import { WeaveAPI, WeaveHelper, Filter, FilterOp } from "@weavechain/weave-js-api";

// Toggle between real and dummy data
export const useBackend = true; //false

export const ORGANIZATION = "csia";
export const SCOPE = "csia_sa";
export const PLUGIN_NAME = "csiaPlugin";

export const PORT = 10443;
const env = "prod";
export const node = env === "staging"
	? "https://staging.standardsinstitute.org:" + PORT + "/8d2f73df4ce3b70a864c5382b4d2c455"
	: env === "prod"
		? "https://app.standardsinstitute.org:" + PORT + "/8d2f73df4ce3b70a864c5382b4d2c455"
		: "http://localhost:" + PORT + "/8d2f73df4ce3b70a864c5382b4d2c455";

export const encrypted = true;
export const [pub, pvk] = WeaveHelper.generateKeys();
export const cfg = WeaveHelper.getConfig(node, pub, pvk, encrypted);
export const nodeApi = new WeaveAPI().create(cfg);

export const table_user_accounts = "user_accounts";


export const READ_DEFAULT_NO_CHAIN = WeaveHelper.Options.READ_DEFAULT_NO_CHAIN;

try {
	await nodeApi.init();
} catch (error) {
	console.error("Error while initiating nodeApi: " + error);
	window.alert("Couldn't init Weavechain node api (" + node + "): " + error);
}

export const getNodeApi = () => {
	return nodeApi;
};

export const backendMapWalletKeyToName = async (walletKey) => {
	if (!useBackend)
		return walletKey;

	const nodeApi = getNodeApi();
	const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");

	const filter = new Filter(FilterOp.eq("wallet_key", walletKey), null, null, null, null, null);
	const result = await nodeApi.read(session, SCOPE, table_user_accounts, filter, READ_DEFAULT_NO_CHAIN);

	console.log("Invitor name: " + JSON.stringify(result));
	if (result.res !== "ok")
		return walletKey;

	return result.data[0]?.name;
}