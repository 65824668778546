import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import cx from "classnames";
import { useDebounce } from "../../helpers/useDebounce";

import s from "./InputWidget.module.scss";

export default function InputWidget(props) {
	const {
		value = "",
		placeholder = "",
		disabled,
		title = "",
		className = "",
		label = "",
		onChange = () => {},
		type = "text",
		isMandatory = false,
		readOnly = false,
		validationError = "",
		validationRegex = "", // for validations
		onFocus = null,
		onClick = null,
	} = props;
	const [valueText, setValueText] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	const debounceValue = useDebounce(valueText, 1);

	useEffect(() => {
		setValueText(value);
	}, [value]);

	useEffect(() => {
		setErrorMessage(validationError);
	}, [validationError]);

	useEffect(() => {
		if (debounceValue || debounceValue === '') {
			if (type === "email") {
				validateEmail(debounceValue);
				// Validate
			} else if (validationRegex) {
				const pattern = new RegExp(validationRegex, "gi");

				try {
					if (pattern.test(debounceValue)) {
						setErrorMessage(null);
						onChange(debounceValue);
					} else {
						setErrorMessage("Please enter a valid value!");
					}
				} catch (error) {
					console.debug(error);
				}
			} else {
				setErrorMessage(null);
				onChange(debounceValue);
			}
		}
		// eslint-disable-next-line
	}, [debounceValue]);

	// ------------------------------------- METHODS -------------------------------------
	const validateEmail = (email) => {
		// eslint-disable-next-line no-useless-escape
		const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

		if (!pattern.test(String(email).toLowerCase())) {
			setErrorMessage("Please enter a valid email address!");
		} else {
			setErrorMessage(null);
			onChange(email);
		}
	};

	return (
		<div className={cx(s.root, className)}>
			{title && (
				<div className={s.title}>
					{title}{isMandatory && <span className="mandatory">*</span>}
				</div>
			)}
			<div className={cx(s.inputContainer)}>
				<Input
					value={valueText || ""}
					onChange={(e) => setValueText(e.target.value)}
					onInput={(e) => setValueText(e.target.value)}
					onFocus={onFocus}
					onClick={onClick}
					type={type}
					readOnly={readOnly}
					placeholder={placeholder}
					disabled={disabled}
					className={cx(s.input, {
						[s.error]: !!errorMessage,
					})}
				/>
			</div>

			{label && <p className={s.label}>{label}</p>}

			{errorMessage ? <p className={s.error}>{errorMessage}</p> : null}
		</div>
	);
}
