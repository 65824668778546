import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import cx from "classnames";
//import bs58 from "bs58";

import s from "./RegisterDetailsPage.module.scss";
import { UserModel, DocumentModel } from "../../../models/AppModels";

import AppButton from "../../../components/app-button/AppButton";
import InputWidget from "../../../components/input-widget/InputWidget";
import UploadFilesButton from "../../../components/upload-files-widget/UploadFilesButton/UploadFilesButton";
import { isPrefilledMagicLink } from "../../../helpers/Utils";
import IndustrySelector from "../../../components/industry-selector/IndustrySelector";
import { backendFetchInvitation } from "../../../backend/invitations";
import { backenCreateApplication } from "../../../backend/applications";
import { backendFetchIndustries } from "../../../backend/industries";
import { dbIndustryToIndustrySelector } from "../../../backend/helpers";
import { backendMapWalletKeyToName } from "../../../backend/common";

/*
		UserModel field	|	Form field
		---------------------------------------------
		name			|	Legal Company Name
		email			|	Company Email Address
		address			|	Company Street Address
		city			|	City
		state			|	State
		zip				|	Zip
		website			|	Company Website
		other_info		|	Other Contact Information
*/

export default function RegisterDetailsPage() {
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();

	const [userModel, setUserModel] = useState<UserModel>({});
	const [documents, setDocuments] = useState([] as DocumentModel[]);
	const [showIndustrySelector, setShowIndustrySelector] = useState(false);
	const [industryName, setIndustryName] = useState("");
	const [invitorName, setInvitorName] = useState("");
	const [token, setToken] = useState("");

	let isMagicLink = isPrefilledMagicLink(location, params);

	// if we have a magic-link, then pre-fill the form
	useEffect(() => {
		if (isMagicLink) {
			const _token = params.token ? params.token : "";

			backendFetchInvitation(_token).then((r) => {
				if (r.res === "err") {
					window.alert("Failed loading invitation from backend. Reason: " + r.message);
					return;
				}
				const invitation = JSON.parse(r.data);
				const _userModel = {
					name: invitation.name,
					email: invitation.email,
					website: invitation.company_website,
					address: invitation.address,
					city: invitation.city,
					state: invitation.state,
					zip: invitation.zip,
					other_info: invitation.other_info,
					beneficial_owners: invitation.beneficial_owners,
					documents: invitation.documents,
					industry_id: invitation.industry_id,
					privilege_level: invitation.privilege_level,
				};
				if (invitation.documents) {
					setDocuments(JSON.parse(invitation.documents));
				}
				setUserModel(_userModel);
				setToken(_token);

				backendFetchIndustries()
					.then(r => dbIndustryToIndustrySelector(r))
					.then(r => {
						let backendIndustries = r.filter(i => Number(i.value) === Number(invitation.industry_id))
						if (backendIndustries.length > 0)
							setIndustryName(backendIndustries[0].label);
						else
							setIndustryName("IndustryId:" + invitation.industry_id);
					})
				backendMapWalletKeyToName(invitation.invitor_pub_key)
					.then(r => setInvitorName(r));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Methods
	const updateModel = (key: string, value: string) => {
		setUserModel({ ...userModel, [key]: value });
	};

	const updateDocuments = (docs: DocumentModel[]) => {
		setDocuments(docs);
	};

	const removeFile = (file: any) => {
		const newFiles = documents.filter((d) => d.id !== file.id);
		setDocuments([...newFiles]);
	};

	const isValidModel = (userModel: UserModel) => {
		if (!userModel.name || userModel.name === '') {
			window.alert("Please input Legal Company Name");
			return false;
		}
		if (!userModel.email || userModel.email === '') {
			window.alert("Please input valid Email");
			return false;
		}
		if (!userModel.address || userModel.address === '') {
			window.alert("Please input Address");
			return false;
		}
		if (!userModel.city || userModel.city === '') {
			window.alert("Please input City");
			return false;
		}
		if (!userModel.state || userModel.state === '') {
			window.alert("Please input State");
			return false;
		}
		if (!userModel.zip || userModel.zip === '') {
			window.alert("Please input ZIP");
			return false;
		}
		if (!userModel.website || userModel.website === '') {
			window.alert("Please input Website");
			return false;
		}
		if (!userModel.beneficial_owners || userModel.beneficial_owners === '') {
			window.alert("Please input Beneficial Owners");
			return false;
		}
		if (documents.length <= 0) {
			window.alert("Please attach Business Formation Documents");
			return false;
		}
		return true;
	};

	const register = () => {
		if (!isValidModel(userModel)) {
			return;
		}
		if (!isMagicLink && !showIndustrySelector) {
			// If the user was not invited show industry selector
			setShowIndustrySelector(true);
			return;
		}
		backenCreateApplication(userModel, documents, token).then((r) => {
			if (r.res !== "ok") {
				window.alert(
					"Couldn't create application on backend side" +
					JSON.stringify(r, null, 2)
				);
				return;
			}

			const needsEmailValidation = JSON.parse(r.data).validateEmail;
			if ("true" === needsEmailValidation) {
				console.log(
					"needs email verification, thus redirecting to verify page"
				);
				navigate("/register/verify", { state: { userModel } });
			} else {
				// TODO: only for magic link flow
				navigate("/register/connect", { state: { userModel } });
			}
		});
	};

	return (
		<div className={s.root}>
			{showIndustrySelector ? (
				<IndustrySelector onChange={(v: any) => updateModel("industryId", v)} />
			) : (
				<div className={s.content}>
					<div className={s.industryName}>{industryName} sign up</div>
					<div className={s.inviter}>Invited by {invitorName}</div>

					<div className={s.inputContainer}>
						<InputWidget
							value={userModel.name || ""}
							isMandatory
							type="text"
							title="Legal Company Name"
							placeholder="Company name"
							onChange={(v: any) => updateModel("name", v)}
						/>
					</div>

					<div className={s.inputContainer}>
						<InputWidget
							value={userModel.email || ""}
							isMandatory
							title="Company Email Address"
							type="email"
							placeholder="example@company.com"
							onChange={(v: any) => updateModel("email", v)}
						/>
					</div>

					<div className={cx(s.inputContainer, s.col2)}>
						<InputWidget
							value={userModel.address || ""}
							title="Company Street Address"
							isMandatory
							type="text"
							placeholder="123 Main St"
							onChange={(v: any) => updateModel("address", v)}
						/>

						<InputWidget
							value={userModel.city || ""}
							title="City"
							isMandatory
							type="text"
							className={s.ml20}
							onChange={(v: any) => updateModel("city", v)}
						/>
					</div>

					<div className={cx(s.inputContainer, s.col2, s.mb16)}>
						<InputWidget
							value={userModel.state || ""}
							isMandatory
							title="State"
							type="text"
							placeholder="California"
							onChange={(v: any) => updateModel("state", v)}
						/>

						<InputWidget
							value={userModel.zip || ""}
							isMandatory
							title="Zip"
							type="text"
							placeholder="90210"
							className={s.ml20}
							onChange={(v: any) => updateModel("zip", v)}
						/>
					</div>

					<div className={cx(s.inputContainer)}>
						<InputWidget
							value={userModel.website || ""}
							isMandatory
							type="text"
							title="Company Website"
							onChange={(v: any) => updateModel("website", v)}
						/>
					</div>

					<div className={cx(s.inputContainer, s.mb16)}>
						<InputWidget
							value={userModel.other_info || ""}
							type="text"
							title="Other Contact Information"
							onChange={(v: any) => updateModel("other_info", v)}
						/>
					</div>

					<div className={cx(s.inputContainer, s.mb16)}>
						<InputWidget
							value={userModel.beneficial_owners || ""}
							isMandatory
							type="text"
							title="Beneficial Owners ≥25% (up to 4)"
							onChange={(v: any) => updateModel("beneficial_owners", v)}
						/>
					</div>

					{/* UPLOAD */}
					<div className={cx(s.inputContainer, s.mb16)}>
						<UploadFilesButton
							isMandatory
							documents={documents}
							onUploadComplete={updateDocuments}
							removeFile={removeFile}
						/>
					</div>

					<AppButton
						text="Next"
						className={s.registerButton}
						onClick={() => register()}
					/>
				</div>
			)}

			{/* Adding industry and referral */}
			{showIndustrySelector ? (
				<>
					<div className={cx(s.inputContainer, s.mb30)}>
						<InputWidget
							value={userModel.referral || ""}
							type="text"
							title="Were you referred?"
							placeholder="Referring party"
							onChange={(v: any) => updateModel("referral", v)}
						/>
					</div>

					<AppButton
						text="Next"
						className={s.referralButton}
						onClick={() => register()}
					/>
				</>
			) : null}
		</div>
	);
}
